export const columns = [
    {
        "title" : "Product",
        "menu" : [
            {
                "title": "Strategy",
                "link": "product-strategy",
                "badge": false
            },
            {
                "title": "Design",
                "link": "product-design",
                "badge": false
            },
            {
                "title": "Development",
                "link": "product-development",
                "badge": false
            },
            {
                "title": "Management",
                "link": "product-management",
                "badge": false
            },
            {
                "title": "MVP",
                "link": "product-mvp",
                "badge": false
            }
        ]
    },
    {
        "title" : "Expertise",
        "menu" : [
            {
                "title": "UI/UX",
                "link": "ui-ux-design",
                "badge": false
            },
            {
                "title": "Backend",
                "link": "backend-development",
                "badge": false
            },
            {
                "title": "Frontend",
                "link": "frontend-development",
                "badge": false
            },
            {
                "title": "Mobile",
                "link": "mobile-development",
                "badge": false
            },
            {
                "title": "Serverless",
                "link": "serverless-development",
                "badge": true
            }
        ]
    }
]

export const metaData = {
    "meta_title": "The Able Guys | Best Product Design Development Agency in United States",
    "meta_description": "Best product design and development agency providing full circle services from start to end",
    "heading": "We are a digital product agency",
    "description": "The Able Guys is a team of multidisciplinary product design, engineering and marketing experts. We focus on UI/UX Design, mobile and web development and augment teams of growing startups and established companies. We only work with a handful of clients at a time, to make them successful with dedicated attention.",
}